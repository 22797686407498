import React, { createContext, useContext, useEffect, useRef } from 'react';

const TimerContext = createContext();

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error("useTimer doit être utilisé à l'intérieur de TimerProvider");
  }
  return context;
};

export const TimerProvider = ({ children }) => {
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
    };
  }, []);

  const startInterval = (callback, delay) => {
    if (timerRef.current) {
      window.clearInterval(timerRef.current);
    }
    timerRef.current = window.setInterval(callback, delay);
  };

  const clearInterval = () => {
    if (timerRef.current) {
      window.clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  return (
    <TimerContext.Provider value={{ startInterval, clearInterval }}>
      {children}
    </TimerContext.Provider>
  );
};
