import { combineReducers } from '@reduxjs/toolkit'
import menu from './menusSlice'
import brandMenu from './brandMenusSlice'
import metadatas from './metadatasSlice'
import orders from './ordersSlice'
import socket from './socketSlice'
import integration from './integrationSlice'
const reducer = combineReducers({
    menu,
    brandMenu,
    metadatas,
    orders,
    socket,
    integration
})
 
export default reducer
