import ApiService from "./ApiService"


export async function apiGetIntegrationServices() {
    return ApiService.fetchData({
        url: '/order/integration/services',
        method: 'get'
    })
}

