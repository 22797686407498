import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    socket: null,
    streamOpen : false,
    message: null
}

export const socketSlice = createSlice({
	name: 'shared/socket',
	initialState,
	reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload
        },
        setMessage: (state, action) => {
            state.message = action.payload
        },
        setStreamOpen: (state, action) => {
            state.streamOpen = action.payload
        }
	},
})

export const { setSocket,setStreamOpen,setMessage } = socketSlice.actions

export default socketSlice.reducer