import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import './locales'
import { TimerProvider } from './TimerContext'

function App() {
    console.log(process.env.NODE_ENV)
    useEffect(() => {
        var worker = new Worker('worker.js')
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
                worker.postMessage({
                    name: 'notification',
                    type: 'notification-granted',
                })
            }
        })
        return () => {
            console.log('unmounting workers')
            worker.terminate()
        }
    }, [])


    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter history={history}>
                    <TimerProvider>
                        <Theme>
                            <Layout />
                        </Theme>
                    </TimerProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

export default App
