import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_BLANK,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_NEXUM,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import apiConfig from 'configs/api.config'
import socketIOClient from "socket.io-client";
import { setSocket } from 'store/shared/socketSlice'

const layouts = {
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./NexumLayout')),
    [LAYOUT_TYPE_NEXUM]: lazy(() => import('./NexumLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type)

    let socket = useSelector((state) => state.base.common.socket)
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const { authenticated } = useAuth()

    useDirection()

    useLocale()

    useEffect(() => {
        console.log("layout socket", socket)
        if(socket && Object.keys(socket).length > 0){
            return;
        }
        const currentDatetime = new Date()
        const openingDate = currentDatetime
            .toISOString()
            .slice(0, 19)
            .replace('T', ' ')

        let params = {
            query: {
                type: 'order_app',
                room: user.s_guid,
                user: JSON.stringify(user),
                openingDate: openingDate,
            },
        }
        socket = socketIOClient(apiConfig.socketEndpoint, params)
        console.log("setted socket", socket)
        
        socket.on('connected', (data) => {
            console.log('connected', data)
            dispatch(setSocket(data))
            apiConfig.socket = socket
        })
    }, [])

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
