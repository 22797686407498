const appConfig = {
    apiPrefix: `${process.env.REACT_APP_API_URL}`,
    prodApiPrefix: `${process.env.REACT_APP_API_URL}`,
    authProdApiPrefix: `${process.env.REACT_APP_AUTH_API_URL}`,
    authenticatedEntryPath: '/orders',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'fr',
    enableMock: true,
    socketUrl : process.env.REACT_APP_SOCKET_URL,
}

export default appConfig
