import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { apiGetIntegrationServices } from 'services/IntegrationService'


export const getIntegrationServices = createAsyncThunk('shared/integration/getIntegrationServices', async () => {
    const response = await apiGetIntegrationServices()
    return response.data
})

// export const getBrandMenus = createAsyncThunk('shared/supplier/getBrandMenus', async (data) => {
//     const response = await apiGetBrandMenus(data)
//     return response.data
// })

// export const getBrandMenu = createAsyncThunk('shared/supplier/getBrandMenu', async (data) => {
//     const response = await apiGetBrandMenu(data)
//     return response.data
// })

// export const getBrandMenuDetail = createAsyncThunk('shared/supplier/getBrandMenu', async (data) => {
//     const response = await apiGetBrandMenuDetails(data)
//     return response.data
// })

// export const createBrandMenu =  async (data) => {
//     const response = await apiCreateBrandMenu(data)
//     return response.data
// }

// export const updateBrandMenu =  async (data) => {
//     const response = await apiPutBrandMenu(data)
//     return response.data
// }

// export const deleteBrandMenu = createAsyncThunk('shared/supplier/deleteBrandMenu',async (data) => {
//     const response = await apiDeleteBrandMenu(data)
//     return response.data
// })



export const integrationSlice = createSlice({
    name: 'shared/integration',
    initialState: {
        services: [],
        loading: false
    },
    reducers: {
        
    },
    extraReducers : {
        
        [getIntegrationServices.fulfilled]: (state, action) => {
            state.services = action.payload
            state.loading = false
        },
        [getIntegrationServices.pending]: (state, action) => {
            state.loading = true
        },
    }
})

export const {  } = integrationSlice.actions

export default integrationSlice.reducer
