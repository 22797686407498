import ApiService from "./ApiService"


export async function apiGetBrandMenus(params) {
    return ApiService.fetchData({
        url: '/order/brand-menus',
        method: 'get',
        params: params
    })
}

export async function apiGetBrandMenuDetails(guid) {
    return ApiService.fetchData({
        url: '/order/brand-menus/' + guid + '/details',
        method: 'get'
    })
}

export async function apiGetBrandMenuDetailsByStore() {
    return ApiService.fetchData({
        url: '/order/brand-menus/store-details',
        method: 'get'
    })
}

export async function apiGetBrandMenuCategories(guid) {
    return ApiService.fetchData({
        url: '/order/brand-menus/' + guid + '/categories',
        method: 'get'
    })
}


export async function apiGetBrandMenuProducts(guid) {
    return ApiService.fetchData({
        url: '/order/brand-menus/' + guid + '/products',
        method: 'get'
    })
}


export async function apiGetBrandMenusTable(params) {
    return ApiService.fetchData({
        url: '/order/brand-menus/query',
        method: 'get',
        params: params
    })
}

export async function apiGetBrandMenu (params) {
    return ApiService.fetchData({
        url: '/order/brand-menus/' + params.id,
        method: 'get',
        params: params
    })
}

export async function apiCreateBrandMenu (data) {
    return ApiService.fetchData({
        url: '/order/brand-menus/',
        method: 'post',
        data
    })
}

export async function apiPutBrandMenu (data) {
    return ApiService.fetchData({
        url: '/order/brand-menus/' + data.id + '/update', 
        method: 'post',
        data
    })
}



export async function apiDeleteBrandMenu (data) {
    return ApiService.fetchData({
        url: '/order/brand-menus/' + data.id + '/delete', 
        method: 'delete',
        data
    })
}

