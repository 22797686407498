import ApiService from "./ApiService"


export async function apiGetOrders(params) {
    return ApiService.fetchData({
        url: '/order/datas',
        method: 'get',
        params: params
    })
}

export async function apiGetLastOrders(params) {
    return ApiService.fetchData({
        url: '/order/datas/last',
        method: 'get',
        params: params
    })
}

export async function apiGetCountOrders(params) {
    return ApiService.fetchData({
        url: '/order/notification/count-orders',
        method: 'get',
        params: params
    })
}

export async function apiGetHistoryOrders(params) {
    return ApiService.fetchData({
        url: '/order/datas/history',
        method: 'get',
        params: params
    })
}


export async function apiGetOrdersTable(params) {
    return ApiService.fetchData({
        url: '/order/datas/query',
        method: 'get',
        params: params
    })
}

export async function apiGetOrder (params) {
    return ApiService.fetchData({
        url: '/order/datas/' + params.id,
        method: 'get',
        params: params
    })
}

export async function apiCreateOrder (data) {
    return ApiService.fetchData({
        url: '/order/datas/',
        method: 'post',
        data
    })
}

export async function apiPutOrder (data) {
    return ApiService.fetchData({
        url: '/order/datas/' + data.id + '/update', 
        method: 'post',
        data
    })
}



export async function apiDeleteOrder (data) {
    return ApiService.fetchData({
        url: '/order/datas/' + data.id + '/delete', 
        method: 'post',
        data
    })
}


export async function apiPutRowOrder(data) {
    return ApiService.fetchData({
        url: 'order/datas/update-row', 
        method: 'post',
        data
    })
}


export async function apiPutOrderProductInMenu(data) {
    return ApiService.fetchData({
        url: 'order/datas/update-order-product', 
        method: 'post',
        data
    })
}

export async function apiCancelOrder(data) {
    return ApiService.fetchData({
        url: 'order/datas/cancel-order', 
        method: 'post',
        data
    })
}
