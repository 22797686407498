import { FaCheckCircle, FaExclamationCircle, FaExclamationTriangle } from "react-icons/fa"
export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'


export const PUBLISHING_STATUS = {
    "published": {
        id : 1,
        color : "green-600",
        icon: <FaCheckCircle />,
        translateKey : 'menu.published'
    },
    "unpublished": {
        id : 1,
        color : "amber-500",
        icon: <FaExclamationTriangle />,
        translateKey : 'menu.unpublished_menu'
    },
    "publish_failed": {
        id : 3,
        color : "red-600",
        icon: <FaExclamationCircle />,
        translateKey : 'menu.publish_failed'
    },
}



export const AVAILABILITIES = [
    { 
        id : 1,
        value: 'available',
        title : 'Available',
    },
    { 
        id : 2,
        value: 'unavailable_today', 
        title: "Unavailable today"
    },
    {
        id : 3,
        value: 'unavailable_indefinitely',
        title: 'Unavailable indefinitely'
    },
]

export const AVAILABILITIES_OBJ = {
    available : 1,
    unavailable_today : 2,
    unavailable_indefinitely : 3
}