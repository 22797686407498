import ApiService from './ApiService'

const PREFIX = '/company-brands';
export async function apiGetCompanyBrands() {
    return ApiService.fetchData({
        url: PREFIX,
        method: 'get'
    })
}


export async function apiGetCompanyBrand(data) {
    return ApiService.fetchData({
        url: PREFIX + '/' + data.id,
        method: 'get',
        params : data
    })
}


export async function apiGetCompanyBrandPharmacies(data) {
    return ApiService.fetchData({
        url: PREFIX + '/' + data.id + '/pharmacies',
        method: 'get',
        params : data
    })
}


export async function apiGetCompanyBrandsMenus(data) {
    return ApiService.fetchData({
        url: PREFIX + '/' + data.id + '/brands-menus',
        method: 'get',
        params : data
    })
}




