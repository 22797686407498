import { PERSIST_STORE_NAME } from 'constants/app.constant'
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { parse, stringify } from 'flatted'
import { openNotification, setNewOrderOverlay } from './shared/ordersSlice'

const cache = {}

const mountWebWorkerMiddleware = (store) => (next) => (action) => {
    if (action.type === 'persist/REHYDRATE') {
        const worker = new Worker('worker.js')
        if(
            store.getState().auth.user &&
            store.getState().auth.session
        )  next(action)
        if(!cache.workerDeployed) {
            cache.workerDeployed = true
            worker.postMessage({
                name: 'notification',
                type: 'user-login',
                user: store.getState().auth.user,
                session: store.getState().auth.session,
            })
            worker.onmessage = function (e) {
                store.dispatch(setNewOrderOverlay(true))
            }
        }
    }
    return next(action)
}

const middlewares = [mountWebWorkerMiddleware]

export const transformCircular = createTransform(
    (inboundState, key) => stringify(inboundState),
    (outboundState, key) => parse(outboundState)
)

const persistConfig = {
    key: PERSIST_STORE_NAME,
    keyPrefix: '',
    storage,
    whitelist: ['auth', 'theme', 'locale', 'shared'],
    // transforms: [transformCircular]
}

const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer()),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(middlewares),
    devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {}

export const persistor = persistStore(store)

export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
        return false
    }
    store.asyncReducers[key] = reducer
    store.replaceReducer(
        persistReducer(persistConfig, rootReducer(store.asyncReducers))
    )
    persistor.persist()
    return store
}

export default store
