import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    apiDeleteOrder,
    apiGetHistoryOrders,
    apiGetLastOrders,
    apiGetOrder,
    apiGetOrders,
    apiPutOrder,
} from 'services/OrderService'

export const getOrders = createAsyncThunk(
    'shared/orders/getOrders',
    async (data) => {
        const response = await apiGetOrders(data)
        return response.data
    }
)

export const getHistoryOrders = createAsyncThunk(
    'shared/orders/getHistoryOrders',
    async (data) => {
        const response = await apiGetHistoryOrders(data)
        return response.data
    }
)

export const getLastOrders = createAsyncThunk(
    'shared/orders/getLastOrders',
    async (data) => {
        const response = await apiGetLastOrders(data)
        return response.data
    }
)

export const getOrder = createAsyncThunk(
    'shared/orders/getOrder',
    async (data) => {
        const response = await apiGetOrder(data)
        return response.data
    }
)

export const updateOrder = createAsyncThunk(
    'shared/orders/updateOrders',
    async (datas, { dispatch }) => {
        const response = await apiPutOrder(datas)
        return response.data.event
    }
)

export const deleteOrder = createAsyncThunk(
    'shared/orders/deleteOrders',
    async (datas, { dispatch }) => {
        const response = await apiDeleteOrder(datas)
        return response.data
    }
)

export const ordersSlice = createSlice({
    name: 'shared/orders',
    initialState: {
        openNewOrderOverlay: false,
        loading: false,
        datas: [],
        currentOrderStatus: 'active',
        history: {
            loading: false,
            datas: [],
        },
        last: {
            loading: false,
            datas: [],
        },
        notifyOrder: false,
        newOrder: {},
    },
    reducers: {
        setNewOrderOverlay: (state, action) => {
            state.openNewOrderOverlay = action.payload
        },
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setCurrentOrderStatus: (state, action) => {
            state.currentOrderStatus = action.payload
        },
        setNewOrder: (state, action) => {
            state.newOrder = action.payload
        },
        openNotification: (state, action) => {
            state.notifyOrder = true
        },
        closeNotification: (state, action) => {
            state.notifyOrder = false
        },
    },
    extraReducers: {
        [getOrders.fulfilled]: (state, action) => {
            state.datas = action.payload
            state.loading = false
        },
        [getOrders.pending]: (state, action) => {
            state.loading = true
        },

        [getHistoryOrders.fulfilled]: (state, action) => {
            state.history.datas = action.payload
            state.history.loading = false
        },
        [getHistoryOrders.pending]: (state, action) => {
            state.history.loading = true
        },
        [getLastOrders.fulfilled]: (state, action) => {
            state.last.datas = action.payload
            state.last.loading = false
        },
        [getLastOrders.pending]: (state, action) => {
            state.last.loading = true
        },
    },
})

export const {
    setCurrentOrderStatus,
    setNewOrderOverlay,
    setNewOrder,
    openNotification,
    closeNotification,
} = ordersSlice.actions

export default ordersSlice.reducer
